import { Module } from 'vuex';
import { State } from '@/models/State';
import asset from './asset';
import user from './user';
import identificationRequest from './identificationRequest';
import payment from './payment';
import pescheck from './pescheck';
import dividend from './dividend';
import gift from './gift';
import subscription from './subscription';
import payNLKMS from './payNLKMS';
import { Vertebra } from '../utils/skeleton';

export default <{ [key: string]: Module<Vertebra, State> }>{
  asset,
  user,
  identificationRequest,
  payment,
  pescheck,
  dividend,
  gift,
  subscription,
  payNLKMS,
};
